<style lang="scss">
.header-fixed {
  @apply fixed left-0 w-full z-10 pt-8;
  background-image: url(/images/сjm-bg.png);
}
</style>

<template>
  <div
    class="flex justify-between items-center"
    :class="{ 'header-fixed': isFixed }"
  >
    <avatar-header class="flex-shrink-0 mr-10" />
    <slot />
    <tasks-progress v-if="!hideProgress" />
  </div>
</template>

<script>
import AvatarHeader from './AvatarHeader.vue';
import ButtonBack from './ButtonBack.vue';
import TasksProgress from './UI/TasksProgress.vue';

export default {
  components: { AvatarHeader, TasksProgress },
  name: 'GameHeader',
  data() {
    return {};
  },
  props: {
    hideProgress: { type: Boolean },
    isFixed: { type: Boolean },
  },
  created() {},
  mounted() {},
  methods: {
    back() {
      this.$router.push({ name: 'Game' });
    },
  },
  computed: {},
};
</script>
