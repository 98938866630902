<style lang="scss">
.l-question-result {
  @apply text-center px-4;
  margin-top: 100px;
  &.withBadge {
    margin-top: 78px;
  }

  @media screen and (min-width: 769px) {
    @apply px-0;
    margin-top: 175px;
  }

  &__supertitle {
    @apply font-vtb-bold uppercase mb-2;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #009fdf;
  }
  &__title {
    @apply font-vtb-bold-italic uppercase mb-4;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: #000000;

    @media screen and (min-width: 769px) {
      font-size: 40px;
      line-height: 54px;
    }
  }
  &__subtitle {
    @apply font-vtb-regular mx-auto;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    max-width: 805px;

    @media screen and (min-width: 769px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__buttons {
    @apply flex flex-col justify-center;
    margin-top: 94px;

    @media screen and (min-width: 769px) {
      @apply flex-row;
      margin-top: 97px;
    }
  }

  &__button {
    width: 100%;

    &:not(:last-child) {
      @apply mb-4;
    }

    @media screen and (min-width: 769px) {
      @apply w-auto;
    }
  }
}
</style>

<template>
  <div class="l-question-result" :class="{ withBadge: withBadge }">
    <slot name="top" />
    <div class="l-question-result__supertitle">{{ supertitle }}</div>
    <div class="l-question-result__title">{{ title }}</div>
    <div class="l-question-result__subtitle">{{ subtitle }}</div>
    <div class="l-question-result__buttons">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultBlock',
  components: {},
  data() {
    return {};
  },
  props: {
    supertitle: { type: String },
    title: { type: String },
    subtitle: { type: String },
    withBadge: { type: Boolean },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
