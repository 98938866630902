export const STAGE_NAMES = {
  1: "Планирование и проектирование",
  2: "Инициирование и анализ",
  3: "Защита и запуск проекта",
}

export const MODAL_NAMES = { QUESTION: 'question-modal', ENDOFGAME: 'end-modal', DIALOG: 'dialog-modal' }

export const DOCS = [
  { name: 'Пользовательское соглашение', link: '/docs/agreement.pdf' },
  { name: 'Согласие на обработку ПД', link: '/docs/privacy-policy.pdf' },
]
