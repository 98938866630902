<style lang='scss' scoped>
</style>

<template>
  <a class="g-vtb-btn-icon flex items-center justify-center cursor-pointer" @click.stop.prevent="showModal">
    <icon-back />
  </a>
</template>

<script>
import IconBack from "./Icons/IconBack.vue";
export default {
  components: { IconBack },
  name: "",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    showModal(e) {
      e.preventDefault();
      this.$modal.show('back-modal');
    }
  },
  computed: {
    backRoute() {
      if (this.$route.name === 'GameStage') {
        return {
          name: 'Game'
        }
      }

      return {
        name: 'LandingPage'
      }
    }
  },
};
</script>

