<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99951 1H11.9995C12.5518 1 12.9995 1.44772 12.9995 2V12C12.9995 12.5523 12.5518 13 11.9995 13H7.99951"
      stroke="#858EA9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0004 7.75C10.4146 7.75 10.7504 7.41421 10.7504 7C10.7504 6.58579 10.4146 6.25 10.0004 6.25V7.75ZM2 7L1.53828 6.40897L0.781724 7L1.53828 7.59103L2 7ZM5.66188 5.09103C5.9883 4.83603 6.0462 4.3647 5.7912 4.03828C5.5362 3.71187 5.06486 3.65397 4.73845 3.90897L5.66188 5.09103ZM4.73845 10.091C5.06486 10.346 5.5362 10.2881 5.7912 9.96172C6.0462 9.6353 5.9883 9.16397 5.66188 8.90897L4.73845 10.091ZM10.0004 6.25H2V7.75H10.0004V6.25ZM2.46172 7.59103L5.66188 5.09103L4.73845 3.90897L1.53828 6.40897L2.46172 7.59103ZM1.53828 7.59103L4.73845 10.091L5.66188 8.90897L2.46172 6.40897L1.53828 7.59103Z"
      fill="#858EA9"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBack",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
