<style lang="scss" scoped>
.g-task-progress {
  @apply w-full box-border rounded-lg relative;
  height: 20px;
  background: #fafafa;
  border: 1px solid #dadef1;
  &__colored {
    @apply rounded-lg w-full absolute z-0;
    top: -8px;
    left: -1px;
    height: 32px;
    background: linear-gradient(90deg, #67c8ff 0%, #00a3ff 100%);
    box-shadow: 0px 4px 8px rgba(0, 163, 255, 0.3);
    transition: width 1s linear;
  }
  &__value {
    @apply font-vtb-regular text-center relative z-10 text-12;
    line-height: 16px;
    color: black;
  }
}
</style>

<template>
  <div class="g-task-progress">
    <div
      class="g-task-progress__colored"
      :style="{ width: `${(answeredQuestions / totalQuestions) * 100}%` }"
    ></div>
    <div class="g-task-progress__value">
      {{ `Решено ${answeredQuestions} из ${totalQuestions}` }}
    </div>
  </div>
</template>

<script>
import useGameState from "@/composables/useGameState";
import { computed } from "@vue/composition-api";

export default {
  name: 'TaskProgress',
  data() {
    return {};
  },
  setup(props, ctx) {
    const { stageData } = useGameState();
    const answeredQuestions = computed(() => {
      if (stageData.value) {
        return stageData.value.answered_stage_questions;
      }
      return 0;
    });
    const totalQuestions = computed(() => {
      if (stageData.value) {
        return stageData.value.total_stage_questions;
      }
      return 0;
    });

    return {
      answeredQuestions,
      totalQuestions,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
