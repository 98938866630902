<style lang="scss" scoped>
.g-user-avatar {
  &__container {
    @apply relative;
    &:after {
      @apply absolute;
      content: "";
      width: 48px;
      height: 32px;
      background-image: url("/images/user-block-mob.svg");
      top: -5px;
      left: -19px;
      
      @screen md {
        width: 80px;
        height: 52px;
        background-image: url("/images/user-block.svg");
        top: -10px;
        left: -32px;
      }
    }
  }
  @apply rounded-lg bg-contain border-none bg-no-repeat z-10 relative;
  --size: 32px;
  height: 20px;
  width: 20px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }

  @screen md {
    height: 32px;
    width: 32px;
  }
}
</style>

<template>
  <div class="g-user-avatar__container">
    <button
      class="g-user-avatar"
      :style="{ backgroundImage: `url('${userAvatar}')` }"
      @click="openSettings"
    />
  </div>
</template>

<script>
import SettingsModal from "./SettingsModal.vue";

export default {
  components: {},
  name: "AvatarHeader",
  props: {},
  created() {},
  mounted() {
    console.log(this.$store.state.user);
  },
  methods: {
    openSettings() {
      this.$modal.show(
        SettingsModal,
        {},
        {
          name: "settings-modal",
          classes: "settings-modal",
          height: "auto",
          width: document.documentElement.clientWidth > 600 ? "600px" : "100%",
          clickToClose: true,
          scrollable: true,
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
  },
  computed: {
    userName() {
      return this.$store.state.user.currentUser?.username;
    },
    userAvatar() {
      return this.$store.state.user.currentUser?.photo;
    },
  },
};
</script>
