<style lang="scss">
.settings-modal {
  @apply rounded-2xl px-4 md:px-6 py-6;
  max-width: 100%;
  left: 0;
}
.close-icon {
  @apply absolute top-0 right-0;
}
</style>

<template>
  <div class="relative">
    <button-modal-close class="close-icon" @click.native="$emit('close')" />
    <g-activity-settings :user="user" :logout="logout" useDocs :docs="docs" />
    <div v-if="isDev" class="flex flex-col">
      <button class="g-vtb-btn mb-7" @click="start">Рестарт</button>
    </div>
  </div>
</template>

<script>
import '@geeckocom/g-activity-settings/dist/index.css';
import ButtonModalClose from './ButtonModalClose.vue';
import useGameState from '@/composables/useGameState';
import { DOCS } from '@/consts';

export default {
  name: 'SettinsModal',
  components: {
    gActivitySettings: () => import('@geeckocom/g-activity-settings'),
    ButtonModalClose,
  },
  data() {
    return {
      docs: DOCS,
    };
  },
  props: {},
  setup() {
    const { restart } = useGameState();
    return { restart };
  },
  created() {},
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
      this.$emit('close');
    },
    start() {
      this.restart();
      this.$emit('close');
    },
  },
  computed: {
    user() {
      const currentUser = this.$store.state.user.currentUser;
      return {
        ...currentUser,
        link: 'https://id.geecko.com/profile',
      };
    },
    isDev() {
      return process.env.NODE_ENV === 'development';
    },
  },
};
</script>
